import React from "react"
import PropTypes from "prop-types"
import { FaTwitter, FaHeart, FaCode } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import "./site.scss"
import Header from "./header"

const Layout = ({ children, title }) => (
    <div className="page-wrapper">
      <Header siteTitle={title} />
      <main>{children}</main>

      <footer className="my-6 level">
      <div className="level-left" style={{ display: "block" }}>
          <div className="line level-item mb-2" style={{ height: "0.2rem", width: "5rem" }}></div>

          <p className="is-size-6 level-item">
            collection by <OutboundLink href="https://www.darkport.co.uk" target="_blank" className="px-2">Paul Price</OutboundLink>
            <OutboundLink href="https://www.twitter.com/darkp0rt" target="_blank" rel="noreferrer"><FaTwitter /></OutboundLink>
          </p>
        </div>

        <div className="level-right mt-3">
          <p className="is-size-6">
            made with <FaHeart style={{ color: "red" }} /> and <FaCode />
          </p>
        </div>
      </footer>
    </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout