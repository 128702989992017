import PropTypes from "prop-types"
import React, { useState } from "react"
import Link from 'gatsby-link'
import { FaEthereum, FaStar } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Header = ({ siteTitle }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  return (
    <header className="navbar">
      <div className="navbar-brand">
        <div className="navbar-" style={{ display: "block" }}>
          <div className="line"></div>
          <h1 className="title has-text-dark is-size-0 is-size-2-mobile">
            <Link to="/" style={{ color: `black`, textDecoration: `none` }}>
              <span className="has-text-weight-light">the</span>depicted.
            </Link>
          </h1>
        </div>

        <a role="button" className={`navbar-burger burger ${mobileMenuVisible && `is-active`}`} aria-label="menu" aria-expanded="false" style={{ height: "auto" }} onClick={(e) => setMobileMenuVisible(!mobileMenuVisible)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      
      <div className={`navbar-menu mt-3 ${mobileMenuVisible && `is-active`}`}>
        <div className="navbar-end is-size-5-desktop is-size-6-tablet is-size-6-mobile">
          <Link to="/rewards/" className="navbar-item mr-3"><FaStar className="mr-2" style={{ color: "gold" }} /> rewards</Link>
          <OutboundLink href="https://opensea.io/collection/thedepicted" rel="noreferrer" target="_blank" className="navbar-item has-text-weight-bold"><FaEthereum className="mr-2" />OpenSea</OutboundLink>
        </div>
      </div>    
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header